import { appConstants } from "../_constants";
import { appHelpers } from "../_helpers";

export const supportService = {
    fetchInvoiceDetails,
    initiateRRRUpdate,
    initiateNewCreator,
    getPaidInvoicesWithoutNotification,
    initiateBulkMarkAsPaid,
    saveInvoiceAgingDetails,
    getInvoiceDetailsMini,
    initiateInvoiceEditApproval,
    searchFaq,
    getPartPaymentSettings,
    initiatePartPayment,
  };

  function fetchInvoiceDetails(payload) {
    return appHelpers
      .getRequest(
        `${appConstants.PAS_URL}/api/SupportService/InvoiceDetailsBDA?InvoiceNumber=${payload.InvoiceNumber}`
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      });
  }

function initiateRRRUpdate(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/SupportService/InitiateRRRUpdateApprovalBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function initiateNewCreator(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/SupportService/InitiateCreatorUpdateApprovalBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getPaidInvoicesWithoutNotification(payload) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Invoice/GetPaidInvoicesWithoutNotificationReportBDA?startDate=${payload.startDate}&endDate=${payload.endDate}&startIndex=${payload.startIndex}&pageSize=${payload.pageSize}&agencyId=${payload.agencyId}`
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function initiateBulkMarkAsPaid(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/SupportService/InitiateBulkMarkAsPaidBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function saveInvoiceAgingDetails(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/InvoiceAgingAnalysis/SaveAgeingConfigurationBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getInvoiceDetailsMini(payload) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/SupportService/InvoiceEditDetailsMiniBDA?InvoiceNumber=${payload.InvoiceNumber}`
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function initiateInvoiceEditApproval(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/SupportService/InitiateInvoiceEditApprovalUpdateBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function searchFaq(payload, cancelToken) {
  return appHelpers
    .postRequest(
      // `https://tranzitechfaqapi.test.vggdev.com/api/Faq/GetProductFaqs`,
      `${appConstants.FAQ_URL}/api/Faq/GetProductFaqs`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getPartPaymentSettings(payload) {
  return appHelpers
    .postRequest(
      // `${appConstants.PAS_URL}/api/SupportService/GetPartPaymentSettings`,
            `${appConstants.PAS_URL}/api/SupportService/GetPartPaymentSettingsBDA`,
      payload,
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function initiatePartPayment(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/SupportService/InitiatePartPaymentSettingsApprovalBDA`,     
       payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}