import React from "react";
import SelectList from "../SelectList/selectList";
import ClipLoader from "react-spinners/ClipLoader";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from "reactstrap";

const PartPaymentCard = ({
  agencyData,
  fieldSelectValue,
  minimumPayments,
  defaultValue,
  selectChange,
  noOfPayments,
  percentagePayment,
  handleChange,
  handleSubmit,
  isEditMode,
  fieldTextField,
  fieldValueKey,
  loading,
  componentErrorMessages
}) => {
  return (
    <Card style={{ borderRadius: "10px", innerHeight: "50%" }}>
      <CardBody style={{ padding: "30px" }}>
        <CardTitle
          style={{
            fontSize: "18px",
            padding: "5px",
            fontWeight: "bold",
            borderBottom: "0.5px solid rgba(0, 0, 0, 0.3)",
            marginBottom: "20px",
          }}
        >
          {isEditMode ? "Edit Part payment" : "Set up new part payment"}
        </CardTitle>
        <Row>
          <Col md="12">
            <FormGroup>
              <Label>Agency name</Label>
              <SelectList
                placeholder="Select agency"
                data={agencyData}
                valueKey={fieldValueKey}
                textField={fieldTextField}
                selectValue={fieldSelectValue || undefined}
                defaultValue={defaultValue}
                // value={fieldSelectValue || undefined}   // Show fieldSelectValue in edit mode, undefined in create mode
                onValueChange={(payload) => {
                  selectChange(payload, "agency");
                }}
              />
            </FormGroup>
            {componentErrorMessages.agencyValueError && (
                <span className={"inputError"}>
                  {componentErrorMessages.agencyValueError}
                </span>
              )}
          </Col>
          <Col md="12">
            <FormGroup>
              <Label>Number of payments</Label>
              <Input
                type="number"
                placeholder="Enter value"
                value={noOfPayments}
                name="noOfPayments"
                onChange={handleChange}
              />
            </FormGroup>
            {componentErrorMessages.noOfPaymentError && (
                <span className={"inputError"}>
                  {componentErrorMessages.noOfPaymentError}
                </span>
              )}
          </Col>
          <Col md="12">
            <FormGroup>
              <Label>Minimum payment</Label>
              <Input
                type="number"
                placeholder="Enter value"
                value={minimumPayments}
                name="minimumPayments"
                onChange={handleChange}
              />
            </FormGroup>
            {componentErrorMessages.minimumPaymentError && (
                <span className={"inputError"}>
                  {componentErrorMessages.minimumPaymentError}
                </span>
              )}
          </Col>

          <Col md="12">
            <FormGroup>
              <Label>Percentage payment</Label>
              <Input
                type="number"
                placeholder="Enter percentage"
                value={percentagePayment}
                name="percentagePayment"
                onChange={handleChange}
              />
            </FormGroup>
            {componentErrorMessages.percentagePaymentError && (
                <span className={"inputError"}>
                  {componentErrorMessages.percentagePaymentError}
                </span>
              )}
          </Col>
          <Col md="12">
            <div className="justify-content-center">
              <Button
                className="btn-rounded create-action-btn text-white w-100"
                onClick={handleSubmit}
                color={"success"}
                disabled={loading}
              >
                {loading ? <ClipLoader color="white" size={20} /> : "Proceed" } 
              </Button>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
export default PartPaymentCard;
