import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input, Row, Col, Button, Table } from "reactstrap";
import {
  FaArrowLeft,
  FaTimes,
  FaCheckCircle,
  FaPlusCircle,
} from "react-icons/fa";
import { connect } from "react-redux";
import ContentLayout from "../../../components/ContentLayout";
import { appHelpers } from "../../../_helpers";
import axios from "axios";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import { supportService } from "../../../_services";
import { alertActions } from "../../../_actions";
import { appConstants } from "../../../_constants";
import "./index.scss";
import moment from "moment";
import Alert from "../../../components/Alert";
import FullScreenModal from "../../../components/FullScreenModal";
import PartPaymentTable from "./record";
import PartPaymentCard from "../../../components/PartPayment";
import { commonService } from "../../../_services";
import { store } from "react-notifications-component";

let source;
const presentDate = new Date();

const PartPayment = (props) => {
  const [partPayment, setPartPayment] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Initialize currentPage with useState
  const [newMail, setNewMail] = useState("");
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertIcon, setAlertIcon] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [componentErrorMessages, setComponentErrorMessages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [agency, setAgency] = useState("");
  const [noOfPayments, setNoOfPayments] = useState("");
  const [minimumPayments, setMinimumPayments] = useState("");
  const [percentagePayment, setPercentagePayment] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedPartPaymentId, setSelectedPartPaymentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10); // Initialize currentPage with useState


  useEffect(() => {
    source = axios.CancelToken.source();

    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";

    // Fetch the part payment settings
    getPartPaymentSettings();
    getAllOrganizations();
    return () => {
      if (source) {
        source.cancel();
      }
    };
  }, []);

  const getPartPaymentSettings = (pageNumber = 1) => {
    props.dispatch(alertActions.startRequest());
    let payload = {
      pageSize: pageSize,
      from: pageNumber,
      parameter: {
        name: "",
      },
    };
    supportService.getPartPaymentSettings(payload).then((res) => {
      let response = res.response;
      props.dispatch(alertActions.stopRequest());

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        setPartPayment(response.results);
        setTotalCount(response.totalCount);
        setCurrentPage(pageNumber === 0 ? 1 : pageNumber);
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        setPartPayment([]); // Set an empty array if there is an error
      }
    });
  };

  const getAllOrganizations = () => {
    props.dispatch(alertActions.startRequest());

    commonService.getAllOrganizations().then((res) => {
      let response = res.response;
      props.dispatch(alertActions.stopRequest());

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        setAgencies(response.data); // Use setPartPayment to store the response data
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        setPartPayment([]); // Set an empty array if there is an error
      }
    });
  };

  const validateInput = () => {
    let errors = {};
    let formValid = true;

    if (agency === "" || agency === 0) {
      errors["agencyValueError"] = "Agency field cannot be empty";
      formValid = false;
    }

    if (noOfPayments === "" || noOfPayments <= 0) {
      errors["noOfPaymentError"] = "Number of payments must be greater than 0";
      formValid = false;
    }

    if (minimumPayments === "" || minimumPayments <= 0) {
      errors["minimumPaymentError"] = "Minimum payment must be greater than 0";
      formValid = false;
    }

    if (
      percentagePayment === "" ||
      percentagePayment < 0 ||
      percentagePayment > 100
    ) {
      errors["percentagePaymentError"] = "Percentage must be between 0 and 100";
      formValid = false;
    }

    setComponentErrorMessages(errors);
    return formValid;
  };

  const handleCreateSubmit = async () => {
    const { dispatch } = props;
    if (validateInput()) {
      setLoading(true); // Start loading spinner

      let payload = {
        agencyId: agency.id,
        numberOfPayments: noOfPayments,
        minimumOutstandingPayment: minimumPayments,
        percentagePayment: percentagePayment,
      };
      dispatch(alertActions.startRequest());
      try {
        const res = await supportService.initiatePartPayment(payload);
        dispatch(alertActions.stopRequest());
        setLoading(false); // Stop loading spinner

        let response = res.response;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          dispatch(
            alertActions.success("new part payment settings sent for approval")
          );
          setShowModal(false);
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.error(res.response));
          if (typeof response === "string") {
            dispatch(alertActions.error(response));
          } else {
            dispatch(alertActions.error(response.message));
          }
        }
      } catch (error) {
        dispatch(alertActions.error("Error fetching invoice details"));
        setLoading(false); // Stop loading spinner
      }
    }
  };

  const handleEditSubmit = async () => {
    const { dispatch } = props;
    if (validateInput()) {
      setLoading(true); // Start loading spinner

      let payload = {
        id: selectedPartPaymentId, // Use the selected ID here
        agencyId: agency?.id,
        numberOfPayments: noOfPayments,
        minimumOutstandingPayment: minimumPayments,
        percentagePayment: percentagePayment,
      };
      dispatch(alertActions.startRequest());
      try {
        const res = await supportService.initiatePartPayment(payload);
        dispatch(alertActions.stopRequest());
        setLoading(false); // Stop loading spinner

        let response = res.response;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          dispatch(
            alertActions.success(
              "Edited part payment settings sent for approval"
            )
          );
          setShowModal(false);
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.error(res.response));
          if (typeof response === "string") {
            dispatch(alertActions.error(response));
          } else {
            dispatch(alertActions.error(response.message));
          }
        }
      } catch (error) {
        dispatch(alertActions.error("Error fetching invoice details"));
        setLoading(false); // Stop loading spinner
      }
    }
  };

  const handleDialogClickOpen = () => {
    setViewDialogOpen(true);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const cancel = () => {
    window.location.reload();
  };


  const handleSelectChange = (payload) => {
    if (payload) {
      setAgency(payload); // Set selected agency
    }
    console.log(payload, "selected agency");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value ? parseFloat(value) : "";
    if (name === "noOfPayments") {
      setNoOfPayments(numericValue);
    } else if (name === "minimumPayments") {
      setMinimumPayments(numericValue);
    } else if (name === "percentagePayment") {
      setPercentagePayment(numericValue);
    }
  };

  const renderAlertModal = (message, icon) => (
    <FullScreenModal
      show={showAlertModal}
      isInvoiceSheet={true}
      callback={() => setShowAlertModal(false)}
      width={"35%"}
      top={"10%"}
    >
      <Alert icon={icon} message={message} />
    </FullScreenModal>
  );

  const renderPaymentModal = () => (
    <FullScreenModal
      show={showModal}
      callback={() => setShowModal(false)}
      width={"37%"}
      top={"10%"}
    >
      <PartPaymentCard
        agencyData={agencies}
        defaultValue={isEditMode ? agency : ""}
        fieldTextField={"name"}
        selectChange={handleSelectChange}
        noOfPayments={noOfPayments}
        minimumPayments={minimumPayments}
        percentagePayment={percentagePayment}
        handleChange={handleChange}
        isEditMode={isEditMode}
        // fieldSelectValue={agency}
        fieldSelectValue={isEditMode ? agency : null} // Null for create mode
        // fieldSelectValue={isEditMode ? agency : agency.name}  // Pass agency object for editing
        fieldValueKey="id"
        handleSubmit={isEditMode ? handleEditSubmit : handleCreateSubmit}
        componentErrorMessages={componentErrorMessages}
        loading={loading}
      />
    </FullScreenModal>
  );

  const handleEdit = (item) => {
    console.log(item, "item");
    const selectedAgency = agencies.find(
      (agency) => agency.id === item.agencyId
    );
    setAgency(selectedAgency); // Set the agency by ID
    setNoOfPayments(item.numberOfPayments);
    setMinimumPayments(item.minimumOutstandingPayment);
    setPercentagePayment(item.percentagePayment);
    setIsEditMode(true); // Set to edit mode
    setShowModal(true); // Show the modal
    setSelectedPartPaymentId(item.id); // Set the ID of the selected partPayment record
    setComponentErrorMessages([]);
  };

  const handleCreate = () => {
    // Reset the form fields for new payment setup
    setAgency("");
    setNoOfPayments("");
    setMinimumPayments("");
    setPercentagePayment("");
    setComponentErrorMessages([]);
    setIsEditMode(false); // Set edit mode to false (new payment)
    setShowModal(true); // Show the modal
  };

  const { requesting } = props;
  console.log(partPayment, "part");
  console.log(agencies, "agencies");

  return (
    <div>
      <ContentLayout requesting={requesting} pageTitle={"Part Payment"}>
        <Row>
          <Col>
            <div className="d-flex justify-content-end">
              <Button
                className="btn-rounded create-action-btn text-white"
                onClick={handleCreate}
                color={"success"}
                disabled={requesting}
              >
                Set up a new part payment
              </Button>
            </div>
          </Col>
        </Row>

        {partPayment?.length === 0 && (
          <EmptyRecordMessage message={"No record match your search"} />
        )}

        {partPayment?.length > 0 && (
          <Row>
            <Col md={"12"}>
              <PartPaymentTable
                tableData={partPayment}
                handleEdit={handleEdit}
                currentPage={currentPage}
                pageSize={pageSize}
                fetchMoreRecords={getPartPaymentSettings}
                totalCount={totalCount}
              />
            </Col>
          </Row>
        )}

        {showAlertModal && renderAlertModal(alertMessage, alertIcon)}
        {showModal && renderPaymentModal()}

        <br />
        <br />
      </ContentLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { requesting } = state;
  return {
    requesting,
  };
};

export default connect(mapStateToProps)(PartPayment);
