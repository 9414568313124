import React from "react";
import indexRoutes from "./routes/";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { history } from "./jwt/_helpers";
import { PrivateRoute } from "./routes/PrivateRoutes";
import { persist, store } from "./_store";
import { PersistGate } from "redux-persist/lib/integration/react";
import ReactNotification from "react-notifications-component";
import LoginPage from "./views/login/login";
import ForgotPasswordPage from "./views/forgot-password/forgotpassword";
import ResetPasswordPage from "./views/reset-password/reset-password";
import ActivateUserPage from "./views/activateuser/index";
import "react-notifications-component/dist/theme.css";
import PaymentSearchInvoice from "./views/sprite-ui/payment-search-invoice";
import PaymentInvoiceDetails from "./views/sprite-ui/payment-invoice-details";
import ConfirmPayment from "./views/sprite-ui/confirm-payment";
import ServiceWorkerWrapper from "./ServiceWorkerWrapper";
import CookiesPolicy from "./views/policies/cookies-policy";
import PrivacyPolicy from "./views/policies/privacy-policy";
import CustomerAwarenessPolicy from "./views/policies/customer-awarness-policy";
import PaymentSecurity from "./views/payment-security/index";
// import FaanPayment from "./views/faan-invoice-payment/";
// import InvoicePaymentTable from "./views/faan-invoice-payment/record";
// import UserProfile from "./views/Settings/user-profile";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
    };
  }

  render() {
    return (
      <Provider store={store}>
        <ReactNotification />
        <PersistGate loading={null} persistor={persist}>
          <ServiceWorkerWrapper />
          <Router basename="/" history={history}>
            <Switch>
              <Route path="/login" component={LoginPage} />
              <Route path="/forgot-password" component={ForgotPasswordPage} />
              <Route path="/resetpassword" component={ResetPasswordPage} />
              <Route path="/activate" component={ActivateUserPage} />
              <Route path="/payment" component={PaymentSearchInvoice} />
              {/* <Route path="/faan-invoice" component={InvoicePaymentTable} /> */}

              <Route
                path="/invoice-details"
                component={PaymentInvoiceDetails}
              />
              <Route path="/confirmPayment" component={ConfirmPayment} />
              <Route path="/cookie-policy" component={CookiesPolicy} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route
                path="/customer-awarness-policy"
                component={CustomerAwarenessPolicy}
              />
              <Route path="/customer/invoice" component={PaymentSecurity} />
              {/* <Route path="/faan-payment" component={FaanPayment} />  */}

              {/* <Route path="/settings-user-profile" component={UserProfile} /> */}

              {indexRoutes.map((prop, key) => {
                return (
                  <PrivateRoute
                    path={prop.path}
                    key={key}
                    component={prop.component}
                  />
                );
              })}
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
