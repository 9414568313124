import React from "react";
import moment from "moment/moment";
import { appConstants } from "../_constants";
import axios from "axios";
import { store } from "../_store";
import { history } from "../_helpers";
import { Input } from "reactstrap";
import { commonActions } from "../_actions";
import { printCSS } from "../components/MobilePrint/print-invoice";

export const appHelpers = {
  formatDate: (d) => {
    if (!d) return "";
    return moment(d.split("T")[0]).format("MMM DD, YYYY");
  },
  formatDateSpecific: (d, format) => {
    return moment(d).format(format || "MMM DD, YYYY");
  },
  formatInvoiceDate: (d) => {
    return moment(d).format("DD/MM/YYYY");
  },
  addHoursToDate: (date, hours) => {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  },
  getRequest: function (url, cancelToken) {
    const { user } = store.getState();
    let combinedHeader = {};

    if (!(Object.keys(user).length === 0 && user.constructor === Object)) {
      combinedHeader.Authorization = `Bearer ${user.access_token}`;
    }
    let config = { headers: combinedHeader, cancelToken };
    return axios
      .get(url, config)
      .then(function (res) {
        return appHelpers.promiseResponse(res.data);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.headers);
          let msg = error.response.data;
          if (error.response.status === 500) {
            msg = "Oops, something went wrong";
          } else if (error.response.status === 404) {
            msg = "Resource not found";
          } else if (error.response.status === 401) {
            msg = appConstants.APP_USER_SESSION_EXPIRED_MESSAGE;
            setTimeout(() => {
              history.push("/login");
            }, 3000);
          }
          return appHelpers.promiseResponse(msg, appConstants.ERROR_RESPONSE);
          // return {statTs: appConstants.REQUEST_FAILURE, data: error.response.data};
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
          // return {status: appConstants.REQUEST_FAILURE, data: error.request};
          const errorMessage =
            error.request.status === 0
              ? appConstants.APP_INTERNET_CONNECTION_MESSAGE
              : error.request;

          return appHelpers.promiseResponse(
            errorMessage,
            appConstants.ERROR_RESPONSE
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
          // return {status: appConstants.REQUEST_FAILURE, data: error.message};
          return appHelpers.promiseResponse(
            error.message,
            appConstants.ERROR_RESPONSE
          );
        }
      });
  },
  getRequestWithoutAuthorization: function (url, cancelToken) {
    let config = { cancelToken };
    return axios
      .get(url, config)
      .then(function (res) {
        return appHelpers.promiseResponse(res.data);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          let msg = error.response.data;
          if (error.response.status === 500) {
            msg = "Oops, something went wrong";
          } else if (error.response.status === 404) {
            msg = "Resource not found";
          } else if (error.response.status === 401) {
            msg = appConstants.APP_USER_SESSION_EXPIRED_MESSAGE;
            setTimeout(() => {
              history.push("/login");
            }, 3000);
          }
          return appHelpers.promiseResponse(msg, appConstants.ERROR_RESPONSE);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          const errorMessage =
            error.request.status === 0
              ? appConstants.APP_INTERNET_CONNECTION_MESSAGE
              : error.request;

          return appHelpers.promiseResponse(
            errorMessage,
            appConstants.ERROR_RESPONSE
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          return appHelpers.promiseResponse(
            error.message,
            appConstants.ERROR_RESPONSE
          );
        }
      });
  },
  postRequest: function (url, payload, cancelToken, addedHeader = null) {
    const { user } = store.getState();

    let combinedHeader = {
      AppName: appConstants.APP_ID,
    };

    if (!(Object.keys(user).length === 0 && user.constructor === Object)) {
      combinedHeader.Authorization = `Bearer ${user.access_token}`;
    }
    if (addedHeader) {
      combinedHeader = { ...combinedHeader, ...addedHeader };
    }

    let config = { headers: combinedHeader, cancelToken };

    return axios
      .post(url, payload, config)
      .then((res) => {
        return appHelpers.promiseResponse(res.data);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          let msg = error.response.data;
          if (error.response.status === 500) {
            msg = "Oops, something went wrong";
          } else if (error.response.status === 404) {
            msg = "Resource not found";
          } else if (error.response.status === 401) {
            msg = appConstants.APP_USER_SESSION_EXPIRED_MESSAGE;
            setTimeout(() => {
              history.push("/login");
            }, 3000);
          }
          return appHelpers.promiseResponse(msg, appConstants.ERROR_RESPONSE);
          // return {statTs: appConstants.REQUEST_FAILURE, data: error.response.data};
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          const errorMessage =
            error.request.status === 0
              ? appConstants.APP_INTERNET_CONNECTION_MESSAGE
              : error.request;

          return appHelpers.promiseResponse(
            errorMessage,
            appConstants.ERROR_RESPONSE
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          return appHelpers.promiseResponse(
            error.message,
            appConstants.ERROR_RESPONSE
          );
        }
      });
  },
  postRequestWithoutAuthorization: function (url, payload, cancelToken) {
    let config = { cancelToken };
    return axios
      .post(url, payload, config)
      .then((res) => {
        return appHelpers.promiseResponse(res.data);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          let msg = error.response.data;
          if (error.response.status === 500) {
            msg = "Oops, something went wrong";
          } else if (error.response.status === 404) {
            msg = "Resource not found";
          } else if (error.response.status === 401) {
            msg = appConstants.APP_USER_SESSION_EXPIRED_MESSAGE;
            setTimeout(() => {
              history.push("/login");
            }, 3000);
          }
          return appHelpers.promiseResponse(msg, appConstants.ERROR_RESPONSE);
          // return {statTs: appConstants.REQUEST_FAILURE, data: error.response.data};
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js

          const errorMessage =
            error.request.status === 0
              ? appConstants.APP_INTERNET_CONNECTION_MESSAGE
              : error.request;

          return appHelpers.promiseResponse(
            errorMessage,
            appConstants.ERROR_RESPONSE
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
          return appHelpers.promiseResponse(
            error.message,
            appConstants.ERROR_RESPONSE
          );
        }
      });
  },
  deleteRequest: function (url, addedHeader = null) {
    const { user } = store.getState();
    let combinedHeader = {};

    if (!(Object.keys(user).length === 0 && user.constructor === Object)) {
      combinedHeader.Authorization = `Bearer ${user.access_token}`;
    }
    if (addedHeader) {
      combinedHeader = { ...combinedHeader, ...addedHeader };
    }

    let config = { headers: combinedHeader };
    // console.log({config})

    return axios
      .delete(url, config)
      .then((res) => {
        return appHelpers.promiseResponse(res.data);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          let msg = error.response.data;
          if (error.response.status === 500) {
            msg = "Oops, something went wrong";
          } else if (error.response.status === 404) {
            msg = "Resource not found";
          } else if (error.response.status === 401) {
            msg = appConstants.APP_USER_SESSION_EXPIRED_MESSAGE;
            setTimeout(() => {
              history.push("/login");
            }, 3000);
          }
          return appHelpers.promiseResponse(msg, appConstants.ERROR_RESPONSE);
          // return {statTs: appConstants.REQUEST_FAILURE, data: error.response.data};
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js

          const errorMessage =
            error.request.status === 0
              ? appConstants.APP_INTERNET_CONNECTION_MESSAGE
              : error.request;

          return appHelpers.promiseResponse(
            errorMessage,
            appConstants.ERROR_RESPONSE
          );
        } else {
          // Something happened in setting up the request that triggered an Error

          return appHelpers.promiseResponse(
            error.message,
            appConstants.ERROR_RESPONSE
          );
        }
      });
  },
  formatPromiseResponse: function (res, resType) {
    let responseType =
      resType === undefined ? appConstants.SUCCESS_RESPONSE : resType;
    return { status: responseType, response: res };
  },
  promiseResponse: function (res, resType) {
    let responseType =
      resType === undefined ? appConstants.SUCCESS_RESPONSE : resType;
    return { status: responseType, response: res };
  },
  interpretErrorResponse(error) {
    let errorMessage = "";
    if (error.response === undefined) {
      errorMessage = "Please check your internet connectivity!";
    } else {
      errorMessage = error.response.data
        ? error.response.data
        : "Unable to handle request";
    }
    if (typeof errorMessage === "string") {
      return errorMessage;
    } else {
      return "Something went wrong!";
    }
  },
  getActiveUser: function () {
    let { user } = store.getState();
    return user ? user : false;

    // let __usr__ = localStorage.getItem(appConstants.USER_KEY);
    // let modifiedUser = JSON.parse(__usr__);
    // console.log(modifiedUser)
    // return __usr__ ? JSON.parse(modifiedUser.user) : false;
  },
  checkPageAuthorization: function (role, page) {
    switch (role) {
      case appConstants.ROLES.SUPER_ADMIN:
        return 1;
      case appConstants.ROLES.CLIENT_ADMIN:
        return 1;
      // return appConstants.ROLES_PAGES.CLIENT_ADMIN.indexOf(page);
      case appConstants.ROLES.CLIENT_USER:
        return 1;
      // return appConstants.ROLES_PAGES.CLIENT_USER.indexOf(page);
      case appConstants.ROLES.VGG_ADMIN:
        return 1;
      case appConstants.ROLES.VGG_USER:
        return 1;
      // return appConstants.ROLES_PAGES.VGG_USER.indexOf(page);
      default:
        return -1;
    }
  },
  isAuthorised: function () {
    const activeUser = appHelpers.getActiveUser();
    const { vggRole } = activeUser;
    const queryString = window.location.href.split("/");
    const queryLength = queryString.length;
    const page_url =
      queryLength > 4
        ? queryString[queryLength - 2]
        : queryString[queryLength - 1];
    if (vggRole) {
      if (this.checkPageAuthorization(vggRole, page_url) > -1) {
        return true;
      } else {
        this.setSessionErrorMessage(
          appConstants.AVI_USER_SESSION_UNAUTHORISED_ERROR_MESSAGE
        );
        return false;
      }
    } else {
      this.setSessionErrorMessage(
        appConstants.AVI_USER_SESSION_AUTHENTICATION_REQUIRED_MESSAGE
      );
      return false;
    }
  },
  setSessionErrorMessage: function (value) {
    localStorage.setItem(appConstants.AVI_USER_SESSION_ERROR, value);
  },
  getSessionErrorMessage: function () {
    return localStorage.getItem(appConstants.AVI_USER_SESSION_ERROR);
  },
  destroySessionExpired: function () {
    if (localStorage.getItem(appConstants.AVI_USER_SESSION_EXPIRED))
      localStorage.removeItem(appConstants.AVI_USER_SESSION_EXPIRED);
    if (localStorage.getItem(appConstants.AVI_USER_SESSION_ERROR))
      localStorage.removeItem(appConstants.AVI_USER_SESSION_ERROR);
  },
  destroySession: function () {
    let __usr__ = localStorage.getItem(appConstants.USER_KEY);
    if (__usr__) localStorage.removeItem(appConstants.USER_KEY);
  },
  numberWithCommas: (x, precision) => {
    if (x) {
      if (x.length < 2) {
        return x;
      }
      if (precision) {
        x = x.toFixed(precision);
      } else {
        x = x.toFixed(2);
      }

      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    }
    return x;
  },
  numberWithCommasOnly: (x, precision) => {
    if (!x && x !== 0) return "";

    if (precision) {
      x = Number(x).toFixed(precision);
    }

    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  },
  convertStringToNumber: (x) => {
    if (typeof x === "number") return x;
    let stringArr = x.split(",");
    let stringWithoutCommas = "";

    for (let i = 0; i < stringArr.length; i++) {
      stringWithoutCommas += stringArr[i];
    }

    return Number(stringWithoutCommas);
  },
  formatLargeNumbersWithDecimals: (labelValue) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(1) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(1) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(1) + "K"
      : Math.abs(Number(labelValue));
  },
  formatLargeNumbers: (labelValue) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? Math.abs(Number(labelValue)) / 1.0e9 + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? Math.abs(Number(labelValue)) / 1.0e6 + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? Math.abs(Number(labelValue)) + "K"
      : Math.abs(Number(labelValue));
  },
  getCurrentWeek: function () {
    let d = new Date();
    let currentDate = moment();
    let weekStart = currentDate.clone().startOf("week");
    let days = [];
    for (let i = 0; i <= d.getDay(); i++) {
      days.push(moment(weekStart).add(i, "days").format("YYYY-MM-DD"));
    }
    let thisWeek = {
      startDate: days[0],
      endDate: days[days.length - 1],
    };
    return thisWeek;
  },

  getCurrentMonth: function () {
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");
    let currentMonth = {
      startDate,
      endDate,
    };
    return currentMonth;
  },
  downloadFile: function (fileUrl, fileName) {
    // get file extension
    let fileExtension = fileUrl.split(".").pop();

    let xhr = new XMLHttpRequest();
    xhr.open("GET", fileUrl);
    xhr.responseType = "blob";

    let that = this;
    xhr.onload = function () {
      that.saveFile(this.response, fileExtension, fileName);
    };
    xhr.send();
  },
  saveFile: (blob, fileExtension, fileName) => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);
    anchor.style = "display: none";

    let url = window.URL.createObjectURL(blob);
    anchor.href = url;
    anchor.download = `${fileName}.${fileExtension}`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  },

  getCurrentDay: function () {
    let currentDay = {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    };
    return currentDay;
  },
  randomArrayOfNumbers: function (length, multiplier) {
    return Array.from({ length: length }, () =>
      Math.floor(Math.random() * multiplier)
    );
  },
  findAllByKey: function (obj, keyToFind) {
    if (obj) {
      return Object.entries(obj).reduce(
        (acc, [key, value]) =>
          key === keyToFind
            ? acc.concat(value)
            : typeof value === "object"
            ? acc.concat(appHelpers.findAllByKey(value, keyToFind))
            : acc,
        []
      );
    }
  },
  tableContent: function (data, checkboxChange, disableAllCheckboxes) {
    let tableRowsContent = [];

    const rows = [...Array(Math.ceil(data.length / 3))];
    const dataRows = rows.map((row, idx) => data.slice(idx * 3, idx * 3 + 3));
    tableRowsContent = dataRows.map((row, idx) => {
      return (
        <tr key={idx}>
          {row.map((item, index) => (
            <td key={index}>
              <span style={{ marginRight: "15px" }}>
                <Input
                  style={{
                    cursor: "pointer",
                    marginLeft: "-9.25rem",
                    marginTop: "0.2rem",
                  }}
                  type="checkbox"
                  checked={item.activated || false}
                  onChange={checkboxChange}
                  disabled={disableAllCheckboxes}
                />
              </span>
              <label>{item.name}</label>
            </td>
          ))}
        </tr>
      );
    });
    return tableRowsContent;
  },
  updatePermissionType: function (
    checkedStatus,
    changedPermission,
    permissionType
  ) {
    let updatedRecord = permissionType.map((item) => {
      if (item.name === changedPermission) {
        item.activated = checkedStatus;
      }
      if (item.address === changedPermission) {
        item.activated = checkedStatus;
      }
      return item;
    });

    return updatedRecord;
  },
  navigateRoute: function (route, pathState) {
    if (pathState) {
      return this.props.history.push({ pathname: route, state: pathState });
    } else {
      return this.props.history.push(route);
    }
  },
  navigateToPreviousRoute: function () {
    this.props.history.goBack();
  },
  cancelModal: function (modalControl, action) {
    const body = document.body;
    body.style.overflow = "scroll";
    this.setState({ [modalControl]: false });
    if (action) {
      this.setState({ [action]: "" });
    }
  },
  dismissModal: function (alert) {
    setTimeout(() => {
      const body = document.body;
      body.style.overflow = "scroll";
      this.setState({ [alert]: false });
    }, 5000);
  },
  getUserRoleId: function (roles, userRole) {
    let role = roles.find((item) => item.roleName === userRole);
    if (role) {
      return role.roleId;
    }
  },
  getName: function (data, id) {
    let record = data.find((item) => item.id === id);
    return record.name;
  },
  deactivateCheckboxes: function (data) {
    return data.map((item) => {
      if (item.activated === true) {
        item.activated = false;
      }
      return item;
    });
  },
  interpretEntityTypeId: (id) => {
    let entity;
    switch (id) {
      case 1:
        entity = "Create Role";
        break;
      case 2:
        entity = "Create Permission";
        break;
      case 3:
        entity = "Create Role Permission";
        break;
      case 4:
        entity = "Create Location";
        break;
      case 5:
        entity = "Create Revenue";
        break;
      case 6:
        entity = "Create Revenue Group";
        break;
      case 7:
        entity = "Create Settlement Account";
        break;
      case 8:
        entity = "Create Schedule";
        break;
      case 9:
        entity = "Create Workflow";
        break;
      case 10:
        entity = "Create User";
        break;
      case 11:
        entity = "Edit User";
        break;
      case 12:
        entity = "Update Settlement Account";
        break;
      case 14:
        entity = "Create Part Payment Setting";
        break;
      case 15:
        entity = "Edit Part Payment Setting";
        break;
      case 16:
        entity = "Mark Invoice As Paid";
        break;
      case 17:
        entity = "Update Ticket Class";
        break;
      case 18:
        entity = "Bulk Mark Invoice As Paid";
        break;
      case 19:
        entity = "Activate Settlement Account";
        break;
      case 20:
        entity = "Deactivate Settlement Account";
        break;
      case 21:
        entity = "Create Settlement Split";
        break;
      case 22:
        entity = "Update Settlement Split";
        break;
      case 23:
        entity = "Upload NIBBS Report Sheet";
        break;
      case 24:
        entity = "Payment Reference Update";
        break;
      case 25:
        entity = "Invoice Creator Update";
        break;
      case 26:
          entity = "Invoice Approval Update";
        break;
        case 27:
          entity = "Part Payment";
        break;
      default:
        entity = "Unknown";
    }
    return entity;
  },
  numberToWords: function (n, custom_join_character) {
    var string = n.toString(),
      units,
      tens,
      scales,
      start,
      end,
      chunks,
      chunksLen,
      chunk,
      ints,
      i,
      word,
      words,
      and = "and";

    // / Remove spaces and commas /
    string = string.replace(/[, ]/g, "");

    // / Is number zero? /
    if (parseInt(string) === 0) {
      return "Zero";
    }

    // / Array of units as words /
    units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];

    // / Array of tens as words /
    tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    // / Array of scales as words /
    scales = [
      "",
      "Thousand",
      "Million",
      "Billion",
      "Trillion",
      "Quadrillion",
      "Quintillion",
      "Sextillion",
      "Septillion",
      "Octillion",
      "Nonillion",
      "Decillion",
      "Undecillion",
      "Duodecillion",
      "Tredecillion",
      "Quatttuor-decillion",
      "Quindecillion",
      "Sexdecillion",
      "Septen-decillion",
      "Octodecillion",
      "Novemdecillion",
      "Vigintillion",
      "Centillion",
    ];

    // / Split user arguemnt into 3 digit chunks from right to left /
    start = string.length;
    chunks = [];
    while (start > 0) {
      end = start;
      chunks.push(string.slice((start = Math.max(0, start - 3)), end));
    }

    // / Check if function has enough scale words to be able to stringify the user argument /
    chunksLen = chunks.length;
    if (chunksLen > scales.length) {
      return "";
    }

    // / Stringify each integer in each chunk /
    words = [];
    for (i = 0; i < chunksLen; i++) {
      chunk = parseInt(chunks[i]);

      if (chunk) {
        // / Split chunk into array of individual integers /
        ints = chunks[i].split("").reverse().map(parseFloat);

        // / If tens integer is 1, i.e. 10, then add 10 to units integer /
        if (ints[1] === 1) {
          ints[0] += 10;
        }

        // / Add scale word if chunk is not zero and array item exists /
        if ((word = scales[i])) {
          words.push(word);
        }

        // / Add unit word if array item exists /
        if ((word = units[ints[0]])) {
          words.push(word);
        }

        // / Add tens word if array item exists /
        if ((word = tens[ints[1]])) {
          words.push(word);
        }

        // / Add 'and' string after units or tens integer if: /
        if (ints[0] || ints[1]) {
          // / Chunk has a hundreds integer or chunk is the first of multiple chunks /
          if (ints[2] || i + 1 > chunksLen) {
            words.push(and);
          }
        }

        // / Add hundreds word if array item exists /
        if ((word = units[ints[2]])) {
          words.push(word + " hundred");
        }
      }
    }

    return words.reverse().join(" ");
  },
  withDecimal: function (n, currency, coin) {
    let nums = parseFloat(Number(n).toFixed(2)).toString().split(".");
    let whole = this.numberToWords(nums[0]);
    if (nums.length === 2) {
      // let floats = parseInt(nums[1]).toString();
      let floats = nums[1];
      let slicedFloats = floats.slice(0, 2);
      let parsedFloats;
      if (slicedFloats.length === 1) {
        parsedFloats = slicedFloats + "0";
      } else {
        parsedFloats = slicedFloats;
      }
      let fraction = this.numberToWords(parsedFloats);
      if (coin) {
        return `${whole} ${currency} and ${fraction} ${coin}`;
      } else if (!coin) {
        return `${whole} ${currency} and ${fraction} `;
      }
    } else {
      return `${whole} ${currency}`;
    }
  },
  getCurrencyValue: (currencies, currencyId) => {
    if (currencies && currencyId) {
      return currencies.find((item) => item.id === currencyId);
    }
    return "";
  },
  getCurrencyXter: (currency) => {
    switch (currency) {
      case "dollar":
        return <span>&#36;</span>;
      case "naira":
        return <span>&#8358;</span>;

      default:
        return <span></span>;
    }
  },
  getBankValue: (banks, bankId) => {
    if (banks && bankId) {
      return banks.find((item) => item.id === bankId);
    }
  },
  getAccountType: (accounts, accountId) => {
    if (accounts && accountId) {
      return accounts.find((item) => item.id === accountId);
    }
  },
  getVggRole: (role) => {
    if (role) {
      return appConstants.VGG_ROLES.find((item) => item.id === role);
    }
  },
  getUserRole: (roles, userRole) => {
    if (userRole) {
      return roles.find((item) => item.roleName === userRole);
    }
  },

  getUserTitle: (titles, titleId) => {
    if (titleId) {
      return titles.find((item) => item.id === titleId);
    }
  },

  getUserRoleById: (roles, roleId) => {
    if (roleId) {
      return roles.find((item) => item.roleId === roleId);
    }
  },

  getOrganizationId: (organizations, organizationId) => {
    if (organizationId) {
      return organizations.find((item) => item.id === organizationId);
    }
  },
  getSupervisor: (supervisors, supervisor) => {
    if (supervisor) {
      return supervisors.find((item) => item.userId === supervisor);
    }
  },
  getProcess: (ProcessList, process) => {
    if (process) {
      return ProcessList.find((item) => item.name === process);
    }
  },
  getCountry: (countries, country) => {
    if (country) {
      return countries.find((item) => item.id === country);
    }
  },
  getState: (states, state) => {
    if (state) {
      return states.find((item) => item.id === state);
    }
  },
  getLGA: (LGAS, LG) => {
    if (LG) {
      return LGAS.find((item) => item.id === LG);
    }
  },
  getDesignations: (designations, designation) => {
    if (designation) {
      return designations.find((item) => item.id === designation);
    }
  },
  getRevenueObj: (revenues, revenueId) => {
    if (revenues && revenueId) {
      return revenues.find((revenue) => revenue.id === revenueId);
    }
    return "";
  },

  getRevenueGroup: (revenueGroups, revenueGroupId) => {
    if (revenueGroups && revenueGroupId) {
      return revenueGroups.find(
        (revenueGroup) => revenueGroup.id === revenueGroupId
      );
    }
    return "";
  },

// get the value of foot note
findOptionValue: (footerOptions, optionValue) => {
  if (footerOptions && optionValue) {
    return footerOptions.find((item) => item.optionValue === optionValue);
  }
  return "";
},

  getCustomer: (customers, customerAIN, customerId) => {
    if (customers && customerId) {
      return customers.find((customer) => {
        return (
          customer.customerAIN === customerAIN || customer.id === customerId
        );
      });
    }
    return "";
  },
  getSubRevenueObj: (subRevenues, subrevenueId) => {
    if (subRevenues && subrevenueId) {
      return subRevenues.find((subRevenue) => subRevenue.id === subrevenueId);
    }
    return "";
  },
  getLocationObj: (locations, locationId) => {
    if (locations && locationId) {
      return locations.find((location) => location.id === locationId);
    }
    return "";
  },
  queryGetBranding: (payload) => {
    const { dispatch } = this.props;
    dispatch(commonActions.getBranding(payload));
  },
  filterCheckboxId: (type, data) => {
    if (type === "permissions") {
      const filteredPermissions = data
        .filter((item) => item.activated === true)
        .map((item) => item.permissionId);
      return filteredPermissions ? filteredPermissions : [];
    }
    if (type === "revenue") {
      const filteredPermissions = data
        .filter((item) => item.activated === true)
        .map((item) => item.revenueId);
      return filteredPermissions ? filteredPermissions : [];
    }
    if (type === "location") {
      const filteredPermissions = data
        .filter((item) => item.activated === true)
        .map((item) => item.locationId);
      return filteredPermissions ? filteredPermissions : [];
    }
  },
  isObject: (val) => {
    if (val === null) {
      return false;
    }
    return typeof val === "object" && val.constructor.name === "Object";
  },
  renameKey: (obj, old_key, new_key) => {
    // check if old key = new key
    if (old_key !== new_key) {
      Object.defineProperty(
        obj,
        new_key, // modify old key
        // fetch description from object
        Object.getOwnPropertyDescriptor(obj, old_key)
      );
      delete obj[old_key]; // delete old key
    }
  },
  showInDays: (totalDays) => {
    if (totalDays > 1) {
      return `${totalDays} days`;
    } else {
      return `${totalDays} day`;
    }
  },
  checkDerivedStateFromPropsMethod: function () {
    const { user, dispatch } = this.props;
    //Call branding if there is no method to display select agency view
    if (
      user.vggRole === appConstants.ROLES.VGG_ADMIN &&
      !this._reactInternalFiber.type.getDerivedStateFromProps
    ) {
      let payload = {
        agencyId: user.organizationId,
        agencyCode: user.organizationCode,
      };

      dispatch(commonActions.getBranding(payload));
    }
  },
  mobilePrintAction: function (areaID) {
    let printContent = document.getElementById(areaID).innerHTML;
    let frame1 = document.createElement("iframe");
    frame1.name = "frame1";
    frame1.style.position = "absolute";
    frame1.style.top = "-1000000px";
    document.body.appendChild(frame1);
    let frameDoc = frame1.contentWindow
      ? frame1.contentWindow
      : frame1.contentDocument.document
      ? frame1.contentDocument.document
      : frame1.contentDocument;
    frameDoc.document.open();
    frameDoc.document.write(`<html><head>`);
    frameDoc.document.write(`<style>`);
    frameDoc.document.write(printCSS);
    frameDoc.document.write(`</style>`);
    frameDoc.document.write(`<head><body>`);
    frameDoc.document.write(printContent);
    frameDoc.document.write(`</body></html>`);
    frameDoc.document.close();
    setTimeout(function () {
      window.frames["frame1"].focus();
      window.frames["frame1"].print();
      document.body.removeChild(frame1);
    }, 500);
    return false;
  },
  fullScreenCallback: function (response, dispatch, prop, action) {
    const body = document.body;
    body.style.overflow = "scroll";
    dispatch(action);
  },
  enableScroll: function () {
    const body = document.body;
    body.style.overflow = "scroll";
  },
  redirectToPaymentGateway: function () {
    const { invoiceNumber } = this.state;
    appHelpers.navigateRoute.call(this, "/payment", invoiceNumber);
  },
  getBrandingFromDomainURL: function () {
    // when user hits the  page,
    // immediately call the getBrandingPath endpoint,
    // posting the domain url to it and
    // set the result of the endpoint in userDataReducer

    let domainURL = window.location.origin;

    this.setState({ domainURL });
    let payload = {
      domainURL,
      agencyId: "",
      agencyCode: "",
    };

    this.props.dispatch(commonActions.getBranding(payload));
  },
  getDomainOrigin: function () {
    return window.location.origin;
  },
  isEmptyObject: function (obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  },
  addNewPropertyName: function (data, oldName, newName) {
    return data.map((item) => {
      return {
        ...item,
        [newName]: item[oldName],
      };
    });
  },
  //This is used to find the values of one array that matches elements from a second array and modify
  // a specified value from the first array. The first array is the returned value.
  compareAndModifyArrayPropertyWithAnotherArray: function (
    arr1,
    arr2,
    arr1ComparisionValue,
    arr1ModifiedValue,
    array2IsArrayOfObjects
  ) {
    let array1Copy = this.copyArrayImmutably(arr1);
    let arr2Copy = [...arr2];
    let arrOneIterator = 0;
    let arrTwoIterator = 0;

    while (
      arrOneIterator < array1Copy.length &&
      arr2Copy.length > 0 &&
      arrTwoIterator <= arr2Copy.length - 1
    ) {
      if (array2IsArrayOfObjects) {
        if (
          array1Copy[arrOneIterator][arr1ComparisionValue] ===
          arr2Copy[arrTwoIterator][arr1ComparisionValue]
        ) {
          array1Copy[arrOneIterator][arr1ModifiedValue] = true;
          arrTwoIterator++;
          arrOneIterator = 0;
        } else if (
          arrOneIterator === array1Copy.length - 1 &&
          arrTwoIterator !== arr2Copy.length - 1
        ) {
          arrTwoIterator++;
          arrOneIterator = 0;
        } else {
          arrOneIterator++;
        }
      } else if (!array2IsArrayOfObjects) {
        if (
          array1Copy[arrOneIterator][arr1ComparisionValue] ===
          arr2Copy[arrTwoIterator]
        ) {
          array1Copy[arrOneIterator][arr1ModifiedValue] = true;
          arrTwoIterator++;
          arrOneIterator = 0;
        } else if (
          arrOneIterator === array1Copy.length - 1 &&
          arrTwoIterator !== arr2Copy.length - 1
        ) {
          arrTwoIterator++;
          arrOneIterator = 0;
        } else {
          arrOneIterator++;
        }
      }
    }
    return array1Copy;
  },
  copyArrayImmutably: function (arr) {
    return arr.map((a) => Object.assign({}, a));
  },
  activateRecord: function (data, record, id, recordId, activateField) {
    let copiedRecord = this.copyArrayImmutably(data);
    let updatedData;
    if (copiedRecord) {
      updatedData = copiedRecord.map((item) => {
        if (item[id] === record[recordId]) item[activateField] = true;
        return item;
      });
    }
    return updatedData;
  },
  filterRecordByValue: function (
    arr,
    record,
    arrValue,
    recordValue,
    convertToInteger
  ) {
    if (arr) {
      return arr.filter(
        (item) =>
          item[arrValue] !==
          (convertToInteger
            ? parseInt(record[recordValue])
            : record[recordValue])
      );
    }
    return null;
  },
  tableContentSettings: function (data, checkboxChange, disableAllCheckboxes) {
    let tableRowsContent = [];

    const rows = [...Array(Math.ceil(data.length / 3))];
    const dataRows = rows.map((row, idx) => data.slice(idx * 3, idx * 3 + 3));
    tableRowsContent = dataRows.map((row, idx) => {
      return (
        <tr key={idx}>
          {row.map((item, index) => (
            <td key={index}>
              <span style={{ marginRight: "15px" }}>
                <Input
                  style={{
                    cursor: "pointer",
                    marginLeft: "-9.25rem",
                    marginTop: "0.2rem",
                  }}
                  type="checkbox"
                  checked={item.isActive || false}
                  onChange={checkboxChange}
                  disabled={disableAllCheckboxes}
                />
              </span>
              <label>{item.invoiceField}</label>
            </td>
          ))}
        </tr>
      );
    });
    return tableRowsContent;
  },
  updateEditableType: function (checkedStatus, changedFields, editableType) {
    let updatedRecord = editableType.map((item) => {
      if (item.invoiceField === changedFields) {
        item.isActive = checkedStatus;
      }
      // if (item.address === changedFields) {
      //   item.activated = checkedStatus;
      // }
      return item;
    });

    return updatedRecord;
  },
  specialCharactersRegEx: (string) => {
    let regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,32}$/;
    return regex.test(string);
  },

  roundOff: (num, places) => {
    const x = Math.pow(10, places);
    return Math.round(num * x) / x;
  },

  convertToNumOfPlaces: (num, decimalPlaces) => {
    let num2 = 1 * 100 ** decimalPlaces;
    return Math.round((num + Number.EPSILON) * num2) / num2;
  },

  // for the faan payment module
  
  numbersWithCommas: (x) => {
    // Ensure x is a number before proceeding
    if (typeof x !== 'number' || isNaN(x)) {
      return '0.00'; // Return a default value or handle as needed
    }
    
    // Round to 2 decimal places
    x = x.toFixed(2);

    // Split the number into whole and decimal parts
    var parts = x.toString().split(".");

    // Add commas to the whole number part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Return the formatted number with commas and decimals
    return parts.join(".");
  }
};
