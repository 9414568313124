import React from "react";
import { Table } from "reactstrap";
import ServerPaginatedTable from "../../../components/ServerPagination";
import "./index.scss";
import { FaEdit} from "react-icons/fa";

const PartPaymentTable = ({ tableData, handleEdit }) => {
  return (
    <Table responsive striped hover>
      <thead className={"table-head-blue"}>
        <tr>
          <th>Agency</th>
          <th>Number of payments</th>
          <th>Minimum payment</th>
          <th>Percentage payment</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {tableData &&
          tableData.map((item, index) => {
            return (
              <tr
                key={index}
                className="table-row"
                // onClick={() => handleEdit(item)}
              >
                <td>{item.agencyName}</td>
                <td>{item.numberOfPayments}</td>
                <td>{item.minimumOutstandingPayment}</td>
                <td>{item.percentagePayment}</td>
                <td>
                  <FaEdit
                    className="text-primary edit-icon"
                    onClick={() => handleEdit(item)} // Handle the edit action here
                  />
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

const TableStructure = ({
  tableData,
  handleEdit,
  currentPage,
  fetchMoreRecords,
  pageSize,
  totalCount,
}) => {
  const pageIndex = currentPage === 0 ? 1 : pageSize * (currentPage - 1) + 1;
  return (
    <ServerPaginatedTable
      currentPage={currentPage}
      fetchMore={fetchMoreRecords}
      pageSize={pageSize}
      totalCount={totalCount}
      target={
        <PartPaymentTable
          tableData={tableData}
          handleEdit={handleEdit}
          pageIndex={pageIndex}
        />
      }
    />
  );
};

export default TableStructure;
